import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit Starter",
    id: "LQTAMN2-US-P6-E1",
    audio: "",
    video: "",
    component: D1,
    //recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        { url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page6/E1/2.jpg" },
      ],
      [
        // Column1
        { url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page6/E1/4.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page6/E1/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-1-details/Stand-up-please.mp3",
        },
      ],
      [
        // Column1
        { url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page6/E1/9.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Starter",
    id: "SB1-S-P6-E1",
    audio: "",
    video: "",
    component: D1,
    //recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        { url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page6/E2/3.jpg" },
      ],
      [
        // Column1
        { url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page6/E2/6.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page6/E2/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-1-details/Sit-down-please.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page6/E2/8.jpg" },
      ],
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page6/E2/10.jpg",
        },
      ],
    ],
  },
};

export default json;
